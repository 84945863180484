<template>
  <div class="home">

    <img style="width: 100%; height: auto; margin:0 auto" src="../assets/login.jpg" />
    <el-form class='form' :label-position="labelPosition" label-width="80px" :model="formLabelAlign" ref="formLabelAlign">
     <el-form-item label="反馈事由" prop="remarks" :rules="[{ required: true, message: '请输入反馈事由' }]">
        <el-input v-model="formLabelAlign.remarks" type="textarea" :rows="3" show-word-limit  maxlength="300"></el-input>
      </el-form-item>
      <el-form-item label="反馈人" prop="userName" :rules="[{ required: true, message: '请输入反馈人名字' }]">
        <el-input v-model="formLabelAlign.userName" show-word-limit  maxlength="100"></el-input>
      </el-form-item>
      <el-form-item label="反馈类型">
        <el-select v-model="formLabelAlign.backlype" placeholder="请选择反馈类型" clearable style="width: 100%;">
          <el-option v-for="item in bossEmailType" :key="item.code" :label="item.value"
							:value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="userPhone"  :rules="[{ pattern:/^1[3456789]\d{9}$/,message:'手机号码格式不正确',trigger:'blur' }]">
        <el-input v-model="formLabelAlign.userPhone"></el-input>
      </el-form-item>
      <el-form-item label="拍照">
        <el-upload
        action="https://oasvc.steriguardmed.net/Mailbox/Upload "
        list-type="picture-card"
        name="input"
        :on-preview="handlePictureCardPreview"
        :on-success="handleAvatarSuccess"
        :on-remove="handleRemove">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>

      </el-form-item>
     
      <el-form-item label="">
        <el-button type="primary" style="width:100%;margin-top: 10px;"
          @click="submitForm('formLabelAlign')">提交</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      labelPosition: "top",
      formLabelAlign: {
        userName: "",
        backlype: '',
        userPhone: "",
        remarks: "",
      },
      bossEmailType: [],
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrl:[]
    };
  },
  mounted() {
    this.queryDic()
  },
  methods: {
    handleAvatarSuccess(res, file) {
        this.imageUrl.push(res.result);
      },
    handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },

    queryDic() {
      let that = this
      axios.get('https://oasvc.steriguardmed.net/sysDictData/queryDictDatalist?code=BossEmailType')
        .then(function (response) {
          if (response.status== 200) {
            that.bossEmailType = response.data.result
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('submit!');
          this.onSubmit()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onSubmit() {
      let that = this
      axios.post('https://oasvc.steriguardmed.net/Mailbox/Add', {
        userName: that.formLabelAlign.userName,
        userPhone: that.formLabelAlign.userPhone,
        remarks: that.formLabelAlign.remarks,
        backType:that.formLabelAlign.backlype,
        fileIdList:that.imageUrl
      })
        .then(function (response) {
          console.log(response.data)
          console.log(response.data.code)
          if (response.data.code == 200) {
            that.$router.push("/about");
            // that.formLabelAlign.userName = '',
            // that.formLabelAlign.userPhone = '',
            // that.formLabelAlign.remarks = ''

          }else {
            that.$message.error(response.data.message);
          }
        })
        .catch(function (error) {
        
          console.log(error);
        });
    }
  },
};
</script>
<style>
.el-form-item{
  margin-bottom: 5px !important;
}
.el-form.el-form--label-top .el-form-item__label {
  padding-bottom: 0px;
}

.form {
  padding: 5px 15px;
}
</style>
